/* General Styling */
.deck-builder-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
  background-color: #3c2a67;
}

h1, h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Flexbox layout for select options, search, and buttons */
.select-container {
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: flex-start; /* Align all items to the left */
  gap: 10px; /* Space between elements */
  padding: 10px;
  width: 100%; /* Ensure it takes full width of the container */
}

.select-container select,
.select-container input,
.select-container button {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Set flex-grow for search input to make it bigger */
.select-container input {
  flex-grow: 2;
}

.select-container button {
  background-color: #ff6600;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.select-container button:hover {
  background-color: #e65c00;
}

button {
  background-color: #ff6600; /* Set to orange as requested */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 10px;
  font-size: 1rem;
}

button:hover {
  background-color: #e65c00; /* Darker orange on hover */
}

/* Centering the "Copy to Clash Royale Deck" Button */
.copy-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.copy-button button {
  background-color: #ff6600; /* Same orange color */
  font-size: 1.1rem;
  padding: 12px 20px;
  border-radius: 8px;
}

.copy-button button:hover {
  background-color: #e65c00;
}

/* Deck Section */
.deck-section {
  margin: 30px 0;
}

.deck-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 15px;
}

.card {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s ease;
}

.card img {
  width: 100%;
  max-width: 100px;
  margin: 0 auto; /* Center image horizontally */
}

.card:hover {
  transform: scale(1.05);
}

.level {
  font-size: 0.9rem;
  color: #ffcc00;
}

.empty-deck-message {
  grid-column: span 4;
  text-align: center;
}

#deck-stats {
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

/* Cards Section */
.cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns for available cards */
  gap: 15px;
}

.card {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center align items */
  justify-content: center; /* Center align items vertically */
}

.card img {
  max-width: 100px;
  margin: 0 auto; /* Center image horizontally */
}

button {
  margin-top: 10px;
  align-self: center; /* Center align buttons */
}

/* Search Section */
.search-section {
  margin: 20px 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px;
  background-color: #3c2a67;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s ease;
}

li:hover {
  background-color: #2b2251;
}

.selected-card {
  margin-top: 10px;
  font-weight: bold;
  color: #fff;
}

/* Mobile and Tablet Responsive Styling */
@media only screen and (max-width: 900px) {
  .select-container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    gap: 10px; /* Reduce gap for smaller screens */
  }

  .deck-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for deck-builder-container */
  }

  .cards-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for available cards container */
  }

  button {
    font-size: 0.8rem;
    padding: 5px;
  }

  h1, h2 {
    font-size: 1.2rem;
  }

  .card img {
    max-width: 80px; /* Adjust image size for mobile */
  }

  .deck-section, .search-section {
    margin: 10px 0;
  }

  .deck-grid {
    gap: 10px;
  }

  #deck-stats {
    font-size: 0.9rem;
  }

  ul {
    font-size: 0.9rem;
  }

  li {
    padding: 8px;
  }

  .empty-deck-message {
    font-size: 0.9rem;
  }
}

/* Small Mobile Styling */
@media only screen and (max-width: 400px) {
  .card img {
    max-width: 70px; /* Further reduce image size for very small screens */
  }

  button {
    font-size: 0.7rem;
  }

  h1, h2 {
    font-size: 1rem;
  }
}
